<app-page-header-notification-bar
  *ngIf="text || subtext"
  [headerTitle]="text"
  [barText]="subtext" />
<div class="mb-3 card">
  <div class="card-header-tab card-header">
    <div class="card-header-title font-size-lg font-weight-normal">
      <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
      Действующий тариф
    </div>
  </div>
  <div class="no-gutters row">
    <div class="col-sm-6 col-md-4 col-xl-4">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-left">
        <div class="widget-chart-content">
          <div class="widget-subheading">Тариф</div>
          <div class="activeTariff">{{ tariff?.name }}</div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-6 col-md-4 col-xl-4">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-left">
        <div class="widget-chart-content">
          <div class="widget-subheading">Действует до</div>
          <div class="activeTariff">
            <span>{{ tariff?.expdate }}</span>
          </div>
          <div class="widget-description opacity-8 text-focus m-0">
            включительно
          </div>
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div
        class="card no-shadow rm-border bg-transparent widget-chart text-left">
        <div [ngSwitch]="tariff?.color" class="widget-chart-content">
          <div class="widget-subheading">Осталось</div>
          <div class="activeTariff">
            <span [class.not-expired]="tariff?.days > 0" [class.expired]="tariff?.days < 1">{{ tariff?.days }}</span>
          </div>
          <div class="widget-description opacity-8 text-focus m-0">дней</div>
        </div>
      </div>
    </div>
  </div>
</div>
<form *ngIf="tariff" [formGroup]="form">
  <div class="scrollWrap">
    <div class="plansCardWrapper">
      <section>
        <div class="flex-centered">
          <section class="pricesHeaders">
            <div
              class="planHeader pale"
              [class.active]="
                controlTariffMonth.value.months === standalone_percent.months
              ">
              <span>
                {{ standalone_percent.name }}
              </span>
              <p *ngIf="standalone_percent.sale" class="percentContent">
                {{ standalone_percent.sale }}
              </p>
            </div>
          </section>
        </div>
        <section class="card-group">
          <app-tariff-item [data]="standalone_card" [isStandalone]="true" [disabled]="!isFreeAvailable" [currentPlan]="tariff" (buttonClicked)="onButtonClicked($event)"></app-tariff-item>
        </section>
      </section>

      <section>
        <div class="flex-centered">
          <section class="pricesHeaders">
            <div
              *ngFor="let category of tariffPercents; let i = index"
              class="planHeader"
              (click)="controlTariffMonth.setValue(category)"
              [class.active]="
                controlTariffMonth.value.months === category.months
              ">
              <span>
                {{ category.name }}
              </span>
              <p *ngIf="category.sale" class="percentContent">
                {{ category.sale }}
              </p>
            </div>
          </section>
        </div>
        <section class="card-group">
          <app-tariff-item
            *ngFor="let item of card_items"
            [data]="item"
            [currentPlan]="tariff"
            (buttonClicked)="onButtonClicked($event)"
            (recalculationClicked)="onRecalculationClicked($event)"
          ></app-tariff-item>
        </section>
      </section>
    </div>
  </div>
</form>

<section class="flex-centered" style="margin-top: 18px;">
  <section style="min-width: 700px; width: 65%; background-color: white; border-radius: 8px; padding: 30px;">
    <table class="table">
      <thead class="custom-border">
        <tr>
          <th scope="col">Доп. услуги</th>
          <th scope="col">Что включает</th>
          <th scope="col">Длительность</th>
          <th scope="col">Стоимость</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="font-weight: 600;">Финансовый аналитик</td>
          <td>
            - как правильно вести финансовую отчетность в вашем бизнесе<br/>
            - чем отличается PnL от ДДС<br/>
            - как правильно вводить операции для корректной финансовой отчетности<br/>
            - вопросы по заполнению сервиса в формате "расскажите мне на созвоне"<br/>
            - как читать финансовые отчеты, на какие показатели обратить самое пристальное внимание<br/>
            </td>
          <td>1 час</td>
          <td>3 490 ₽</td>
          <td>
            <button (click)="payExtraService(3490, 'Финансовый аналитик')" class="btn btn-success pay-button">Оплатить</button>
            <button class="btn tg-link">
              <a href="https://t.me/mpsurf">
                <img src="assets/images/tg.png" height="20" width="20" alt="telegram logo" > <span>Подробнее</span>
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td style="font-weight: 600;">Финансовый директор (CIMA)</td>
          <td>
            комплексный аудит финансовой отчетности и ключевых показателей бизнеса от эксперта по финансам селлеров:<br/>
            - развернутая обратная связь по состоянию финансовых показателей<br/>
            - по ключевым показателям бизнеса <br/>
            - по управленческом учету<br/>
            - точки роста бизнеса, здоровье финансовой модели<br/>
            - с какими финансовыми показателями нормально развиваться в вашей нише<br/>
            - рычаги масштабирования в вашем бизнесе<br/>
            </td>
          <td>1 час</td>
          <td>5 990 ₽</td>
          <td>
            <button (click)="payExtraService(5990, 'Финансовый директор (CIMA)')" class="btn btn-success pay-button">Оплатить</button>
            <button class="btn tg-link">
              <a href="https://t.me/mpsurf">
                <img src="assets/images/tg.png" height="20" width="20" alt="telegram logo" > <span>Подробнее</span>
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td style="font-weight: 600;">Сопровождение фин. директора (CIMA)</td>
          <td>
            5 созвонов в месяц по 1 часу. Комплексная проработка вашего запроса по финансовам и ключевым показателям бизнеса от эксперта корпоративных финансов селлеров:<br/>
            - развернутая обратная связь по состоянию финансовых показателей<br/>
            - по ключевым показателям бизнеса <br/>
            - по управленческом учету<br/>
            - road-map по работе на месяц<br/>
            - точки роста бизнеса, здоровье финансовой модели<br/>
            - с какими финансовыми показателями эффективно расти и развиваться в вашей нише<br/>
            - рычаги масштабирования в вашей бизнес-модели<br/>
            </td>
          <td>1 месяц</td>
          <td>30 000 ₽</td>
          <td>
            <button (click)="payExtraService(30000, 'Сопровождение фин. директора (CIMA)')" class="btn btn-success pay-button">Оплатить</button>
            <button class="btn tg-link">
              <a href="https://t.me/mpsurf">
                <img src="assets/images/tg.png" height="20" width="20" alt="telegram logo" > <span>Подробнее</span>
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td style="font-weight: 600;">Дополнителный кабинет</td>
          <td></td>
          <td>1 месяц</td>
          <td>2 000 ₽/мес.</td>
          <td>
            <button class="btn tg-link">
              <a href="https://t.me/mpsurf">
                <img src="assets/images/tg.png" height="20" width="20" alt="telegram logo" > <span>Подробнее</span>
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td style="font-weight: 600;">Дополнителный пользователь</td>
          <td></td>
          <td>1 месяц</td>
          <td>2 000 ₽/мес.</td>
          <td>
            <button class="btn tg-link">
              <a href="https://t.me/mpsurf">
                <img src="assets/images/tg.png" height="20" width="20" alt="telegram logo" > <span>Подробнее</span>
              </a>
            </button>
        </tr>
        <tr>
          <td style="font-weight: 600;">Напиши менеджеру чтобы узнать подробнее</td>
          <td>
            - Рассрочка<br/>
            - Подарочные сертификаты<br/>
            - Оплата по счёту и другие вопросы<br/>
          </td>
          <td></td>
          <td>Бесплатно</td>
          <td>
            <button class="btn tg-link">
              <a href="https://t.me/mpsurf">
                <img src="assets/images/tg.png" height="20" width="20" alt="telegram logo" > <span>Подробнее</span>
              </a>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</section>


<p-confirmDialog [baseZIndex]="10000" [style]="{ width: '44%' }" />
<p-toast />


<ng-template #formTpl>
  <ng-container *ngIf="!isBonusesLoading; else loadingTpl">
    <div class="formTpl">
      <section style="font-weight: bold; margin-bottom: 24px; font-size: 1.6rem;">
        Оплатить
      </section>
      <section>
        <form [formGroup]="createBillFormGroup" class="upper-group">
          <div class="item">
            <div class="title">Тариф</div>
            <div class="content">{{ createBillFormGroup.controls.tariff_name.value }}</div>
          </div>
          <div class="item">
            <div class="title">Период</div>
            <div class="content">{{ createBillFormGroup.controls.period.value }}</div>
          </div>
          <div class="item">
            <div class="title">Стоимость</div>
            <div class="content">{{ createBillFormGroup.controls.cost.value }}</div>
          </div>
          <div class="item">
            <div class="title">Скидка</div>
            <div class="content">{{createBillFormGroup.controls.discount.value}}</div>
          </div>
          <div class="item mt-4 mb-4"> 
            <div class="title">Итого к оплате</div>
            <section style="display: flex; align-items: center;">
              <h4 style="font-weight: 700; margin-top: 9px;">{{createBillFormGroup.controls.total_cost.value}}</h4>
            </section>
          </div>
        </form>
        <div class="lower-group">
          <div class="item">
            <div class="title">Промокод <i class="fa fa-question-circle" pTooltip="Применяется к итоговой сумме"></i></div>
              <div class="p-inputgroup">
                <input tabindex="-1" type="text" pInputText [(ngModel)]="applied_promo"/>
                <button style="font-size: 12px;" class="pay-button-pale" tabindex="-1" type="button" (click)="applyPromokod()" pButton label="Применить"></button>
              </div>
          </div>
          <div class="item">
            <div class="title">Бонусы <i class="fa fa-question-circle" pTooltip="Применяются к итоговой сумме бонусы за реферальную программу"></i></div>
            <div class="p-inputgroup">
              <input tabindex="-1" type="text" pInputText [(ngModel)]="applied_bonus"/>
              <button style="font-size: 12px;" class="pay-button-pale" tabindex="-1" type="button" (click)="useBonuses()" pButton label="Использовать"></button>
            </div>
          </div>
          <div class="item">
            <div class="title"></div>
            <div>
              Доступно: {{createBillFormGroup.controls.available_bonuses.value}}
            </div>
          </div>
          <form [formGroup]="createBillFormGroup" class="item mb-24">
            <div class="title">Скидка бонсуами и по промокоду</div>
            <div class="content-wrapper">
              <div class="content">{{createBillFormGroup.controls.discount_bonus.value}}</div>
              <div class="content">{{createBillFormGroup.controls.discount_promo.value}}</div>
            </div>
          </form>
        </div>
      </section>
      <footer>
        <button style="width: 89px;" class="btn pay-button" (click)="createBill(selectedItem)">Оплатить</button>
        <button class="btn cancel-button" (click)="closeModal()">Отменить</button>
      </footer>
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingTpl>
  <section class="loading-modal">
    <p-progressSpinner ariaLabel="loading" />
  </section>
</ng-template>