import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ICurrentTariff,
  ITariffItem,
  ITariffPercents,
  ITariffPricesItem,
  TariffPlansTitles,
} from './tariff.interface';
import {
  STANDALONE_PERCENT,
  TARIFF_ITEMS,
  TARIFF_PERCENTS,
  TARIFF_PERCENTS_CODES,
  TARIFF_PLAN_CODE,
  TARIFF_PLANS,
  TARIFF_PLANS_PRICE_PER_DAY,
  TARIFF_PLANS_VALUES,
  TARIFF_PRICES,
} from './tariff.mock';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment/moment';
import { CommonType2 } from 'app/shared/interfaces/common.interface';
import { LOAD_STATUSES, LOAD_STATUSES_EN } from 'app/shared/contants/statuses';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from 'app/menu-tabs/abstract-menu-tab.component';
import { TariffItemComponent } from './components/tariff-item/tariff-item.component';
import { DecimalPipe } from '@angular/common';
import { UnitPipe } from 'app/shared/pipes/unit.pipe';
import { switchMap, tap } from 'rxjs';
import { converterMapping } from './mappings';

const decimalPipe = new DecimalPipe('ru-RU');
const unitPipe = new UnitPipe();

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.components.scss'],
  providers: [MessageService, ConfirmationService],
})
export class TariffComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  readonly TARIFF_PLANS_TITLES = TariffPlansTitles;

  readonly standalone_card: ITariffItem = TARIFF_ITEMS.find(
    item => item.name === 'S'
  );
  readonly card_items: ITariffItem[] = TARIFF_ITEMS.filter(
    item => item.name !== 'S'
  );

  tariffPercents: ITariffPercents[] = TARIFF_PERCENTS;
  standalone_percent: ITariffPercents = STANDALONE_PERCENT;

  readonly tariffPlans: { id: number; title: string; code: string }[] =
    TARIFF_PLANS;

  tariff: ICurrentTariff;

  currentPrices: ITariffPricesItem = TARIFF_PRICES.three_month_selector;

  readonly planRates: ITariffPricesItem = {
    base: 4200,
    extended: 10000,
    professional: 15000,
    corporate: 25000,
  };

  form = new FormGroup({
    tariffMonth: new FormControl(this.tariffPercents[2]), // Initialize with default value
    tariffPlan: new FormControl(this.tariffPlans[2]), // Initialize with default value
  });

  createBillFormGroup = new FormGroup({
    tariff_name: new FormControl(''),
    period: new FormControl(''),
    cost: new FormControl(''),
    discount: new FormControl(''),
    total_cost: new FormControl(''),
    available_bonuses: new FormControl(''),
    discount_bonus: new FormControl(''),
    discount_promo: new FormControl(''),
  });

  applied_bonus = null;
  applied_promo = null;


  activeSelector = TARIFF_PERCENTS[2].selector;

  selectPlanCard = TARIFF_PRICES.twelve_month_selector;

  selectedItem: ITariffItem | null = null;

  isFreeAvailable = false;

  text = '';
  subtext = '';
  hashId = '';
  isBonusesLoading = false;

  get controlTariffMonth(): FormControl {
    return this.form.controls.tariffMonth;
  }

  get controlTariffPlan(): FormControl {
    return this.form.controls.tariffPlan;
  }

  @ViewChildren(TariffItemComponent)
  tariffCmps!: QueryList<TariffItemComponent>;
  @ViewChild('formTpl') formTpl: TemplateRef<unknown>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.getTariff();
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(({ tariffMonth }) => {
        this.recalculate(tariffMonth);
      });
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(
          item => item.pageTitle === 'profile/tariff'
        ) || { die_text: null, die_title: null };
        this.text = die_title;
        this.subtext = die_text;
        this.isFreeAvailable = !!res.data.access_tariff_s;
      });
  }

  recalculateBill(planName: string): void {
    let id = 18;
    if(planName === 'L')
      id = 19;
    if(planName === 'XL')
      id = 20
    this._mpSurfService.loadByOld({tariff_id: id, type: 'preview'}, 'recalculation', 'data')
    .subscribe((data: { tariff: string, validDate: string, validDay: number, is_error?: number, msg?: string }) => {
      if(data.is_error) {
        this.showPopUpMessage(
          LOAD_STATUSES_EN.ERROR,
          data.msg,
          ''
        );
      }
      else {
        this._confirmationService.confirm({
          message: `
            <p class="me-2">Тариф: ${data.tariff}</p>
            <p class="m-0">Действует до: ${data.validDate}</p>
            <p class="m-0">Доступно дней: ${data.validDay}</p>
            <br>
            <p class="m-0 text-justify">Обращаем ваше внимание, что ручное понижение тарифа невозможно. 
            Для перехода на пониженный тариф нужно обратиться в Тех. поддержку.</p>
            <br>
            <p class="m-0">Вы уверены, что хотите перейти на ${data.tariff} тариф?</p>`,
    
          header: 'Подтверждение изменения тарифа',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Да',
          rejectLabel: 'Нет',
          accept: () => {
            this._mpSurfService
              .loadByOld({ tariff_id: id, type: 'save' }, 'recalculation', 'data')
              .pipe(untilDestroyed(this))
              .subscribe(
                (response: CommonType2) => {
                  response.is_error === 0
                    ? this.showPopUpMessage(
                        LOAD_STATUSES_EN.SUCCESS,
                        LOAD_STATUSES.SUCCESS,
                        'Переход успешно выполнен'
                      )
                    : this.showPopUpMessage(
                        LOAD_STATUSES_EN.ERROR,
                        response.msg,
                        ''
                      );
                  this.getTariff();
                },
                () => {
                  this.showPopUpMessage(
                    LOAD_STATUSES_EN.ERROR,
                    PopUpMessages.createFailedSummary,
                    PopUpMessages.createFailedMessage
                  );
                }
              );
          },
          reject: () => {},
        });
      }
    })
  }

  recalculate(v: ITariffPercents): void {
    switch (v.id) {
      case 0:
        this.tariffCmps.forEach(cmp => (cmp.recalculationMode = true));
        this.tariffCmps.toArray()[1].data = {
          ...TARIFF_ITEMS[1],
          calculation_period: '',
          month_summary: '0 ₽/мес',
          calculation_result: '0 ₽',
          calculation_result_old: '',
        };
        this.tariffCmps.toArray()[2].data = {
          ...TARIFF_ITEMS[2],
          calculation_period: '',
          month_summary: '0 ₽/мес',
          calculation_result: '0 ₽',
          calculation_result_old: '',
        };
        this.tariffCmps.toArray()[3].data = {
          ...TARIFF_ITEMS[3],
          calculation_period: '',
          month_summary: '0 ₽/мес',
          calculation_result: '0 ₽',
          calculation_result_old: '',
        };
        break;
      case 1:
        this.tariffCmps.forEach(cmp => (cmp.recalculationMode = false));
        this.tariffCmps.toArray()[1].data = {
          ...TARIFF_ITEMS[1],
          calculation_period: 'за 3 месяца',
          month_summary: '6 200 ₽/мес',
          calculation_result: '18 600 ₽',
          calculation_result_old: ''
        };
        this.tariffCmps.toArray()[2].data = {
          ...TARIFF_ITEMS[2],
          calculation_period: 'за 3 месяца',
          month_summary: '7 800 ₽/мес',
          calculation_result: '23 400 ₽',
          calculation_result_old: ''
        };
        this.tariffCmps.toArray()[3].data = {
          ...TARIFF_ITEMS[3],
          calculation_period: 'за 3 месяца',
          month_summary: '13 200 ₽/мес',
          calculation_result: '39 600 ₽',
          calculation_result_old: ''
        };
        break;

      case 2:
        this.tariffCmps.forEach(cmp => (cmp.recalculationMode = false));
        this.tariffCmps.toArray()[1].data = {
          ...TARIFF_ITEMS[1],
          calculation_period: 'за 6 месяцев',
          month_summary: '4 960 ₽/мес',
          calculation_result: '29 760 ₽',
          calculation_result_old: '37200 ₽',
        };
        this.tariffCmps.toArray()[2].data = {
          ...TARIFF_ITEMS[2],
          calculation_period: 'за 6 месяцев',
          month_summary: '6 240 ₽/мес',
          calculation_result: '37 440 ₽',
          calculation_result_old: '46 800 ₽',
        };
        this.tariffCmps.toArray()[3].data = {
          ...TARIFF_ITEMS[3],
          calculation_period: 'за 6 месяцев',
          month_summary: '10 560 ₽/мес',
          calculation_result: '63 360 ₽',
          calculation_result_old: '79 200 ₽',
        };
        break;

      case 3:
        this.tariffCmps.forEach(cmp => (cmp.recalculationMode = false));
        this.tariffCmps.toArray()[1].data = {
          ...TARIFF_ITEMS[1],
          calculation_period: 'за 12 месяцев',
          month_summary: '4 650 ₽/мес',
          calculation_result: '55 800 ₽',
          calculation_result_old: '74 400₽',
        };
        this.tariffCmps.toArray()[2].data = {
          ...TARIFF_ITEMS[2],
          calculation_period: 'за 12 месяцев',
          month_summary: '5 850 ₽/мес',
          calculation_result: '70 200 ₽',
          calculation_result_old: '93 600 ₽',
        };
        this.tariffCmps.toArray()[3].data = {
          ...TARIFF_ITEMS[3],
          calculation_period: 'за 12 месяцев',
          month_summary: '9 900 ₽/мес',
          calculation_result: '118 800 ₽',
          calculation_result_old: '158 400 ₽',
        };
        break;
      default:
        break;
    }
  }

  onButtonClicked(data: ITariffItem): void {
    const periodId = this.controlTariffMonth.value.id;
    const tariffPlan = this.tariffPercents.find(item => item.id === periodId);
    const id = tariffPlan.ids[data.name];
    this._mpSurfService
      .loadByOld({ id: data.name === 'S' ? 27 : id }, 'createBill', 'data')
      .pipe(
        untilDestroyed(this),
        tap(res => (this.hashId = res.id)),
        switchMap(() =>
          this._mpSurfService.loadByOld({ hash: this.hashId }, 'getOneBillV2', 'data')
        )
      )
      .subscribe(v => {
        this._handleBillResponse(v);
        this.applied_bonus = null;
        this.applied_promo = null;
        this._modalService.open(this.formTpl, { centered: true });
      });
  }

  onRecalculationClicked(data: ITariffItem): void {
    this.recalculateBill(data.name);
  }

  getDiscount(item: ITariffItem): string {
    if (!item.calculation_result_old) return '-';
    const old = parseInt(item.calculation_result_old.replace(' ', ''));
    const updated = parseInt(item.calculation_result.replace(' ', ''));
    const diff = old - updated;
    return unitPipe.transform(decimalPipe.transform(diff, '1.0-1'), 'rubles');
  }

  createBill(): void {
    this._mpSurfService
      .loadByOld({ id_hash: this.hashId }, 'generateUrlPayment', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (a: { url: string }) => {
          this._modalService.dismissAll();
          if (a.url) {
            window.location.href = a.url;
          } else {
            window.location.reload();
          }
        },
        (err) => {
          this.showPopUpMessage('error', 'Ошибка оплаты. Пожалуйста повторите попытку позже', '');
          this._modalService.dismissAll();
        }
      );
  }

  closeModal(): void {
    this._modalService.dismissAll();
  }

  useBonuses(): void {
    const bonuses = this.applied_bonus;
    if(bonuses) {
      this.isBonusesLoading = true;
      this._mpSurfService
        .loadByOld(
          {
            id_hash: this.hashId,
            bonuses: +this.applied_bonus
          },
          'useBonuses',
          'data'
        )
        .pipe(untilDestroyed(this))
        .subscribe(response => {
          this.isBonusesLoading = false;
          if (response.is_error) {
            this.showPopUpMessage('error', response.msg, '');
          } else {
            this.showPopUpMessage('success', 'Бонусы успешно применены', '');
            this.applied_bonus = null;
            if(typeof response.msg === 'number') {
              this.applied_bonus = response.msg
            }
            this._mpSurfService.loadByOld({ hash: this.hashId }, 'getOneBillV2', 'data')
              .pipe(untilDestroyed(this))
              .subscribe(v => {
                this._handleBillResponse(v);
            });
          }
        });
    }
  }

  applyPromokod(): void {
    const promokod = this.applied_promo;
    if (promokod) {
      const body = {
        promokod,
        id_hash: this.hashId,
      };
      this._mpSurfService
        .loadByOld(body, 'getPromokod', 'data')
        .pipe(untilDestroyed(this))
        .subscribe(response => {
          this.isBonusesLoading = false;
        if (response.is_error) {
          this.showPopUpMessage('error', response.msg, '');
        } else {
          this.showPopUpMessage('success', response.msg, '');
          this._mpSurfService.loadByOld({ hash: this.hashId }, 'getOneBillV2', 'data')
            .pipe(untilDestroyed(this))
            .subscribe(v => {
              this._handleBillResponse(v);
            });
          }
        });
    }
  }

  payExtraService(cost: number, name: string): void {
    this._mpSurfService
      .loadByOld({ cost, name }, 'createBillServices', 'data')
      .pipe(
        untilDestroyed(this),
        tap(res => (this.hashId = res.id)),
      )
      .subscribe(v => this.createBill());
  }

  private _handleBillResponse(v: any): void {
      this.applied_bonus = v.data[0].used_bonuses,
      this.createBillFormGroup.setValue({
        tariff_name: v.data[0].tariff_name,
        period: v.data[0].period + ' дней',
        cost: unitPipe.transform(
          decimalPipe.transform(v.data[0].cost, '1.0-0'),
          'rubles'
        ),
        discount: unitPipe.transform(
          decimalPipe.transform(v.data[0].discount, '1.0-0'),
          'rubles'
        ),
        total_cost: unitPipe.transform(
          decimalPipe.transform(v.data[0].total_cost, '1.0-0'),
          'rubles'
        ),
        available_bonuses: unitPipe.transform(
          decimalPipe.transform(v.data[0].available_bonuses, '1.0-0'),
          'rubles'
        ),
        discount_bonus: unitPipe.transform(
          decimalPipe.transform(v.data[0].discount_bonus, '1.0-0'),
          'rubles'
        ),
        discount_promo: v.data[0].discount_promo + ' дней',
      });
  }

  private tariffCode(name: string): string {
    let code = 'base';
    switch (name) {
      case 'Базовый':
      case TariffPlansTitles.BASE:
        code = TARIFF_PLAN_CODE.base;
        break;

      case 'Расширенный':
      case TariffPlansTitles.EXTENDED:
        code = TARIFF_PLAN_CODE.extended;
        break;

      case 'Профессиональный':
      case TariffPlansTitles.PROFESSIONAL:
        code = TARIFF_PLAN_CODE.professional;
        break;
      case 'Бесплатный':
      case TariffPlansTitles.FREE:
        code = TARIFF_PLAN_CODE.free;
        break;
    }

    return code;
  }

  private getTariff(): void {
    this._mpSurfService
      .loadByOld({}, 'getTariff', 'data')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: ICurrentTariff) => {
          this.tariff = response;
          this.tariff.code = this.tariffCode(this.tariff.name);
          this.tariff.value = TARIFF_PLANS_VALUES[this.tariff.code];

          if (this.tariff.name !== 'XL' && this.tariff.period === '12 месяцев') {
            this.tariffPercents = this.tariffPercents.filter(
              a => a.name !== TARIFF_PERCENTS_CODES.recount
            );
          }
        },
        () => {
          this.showPopUpMessage(
            LOAD_STATUSES_EN.ERROR,
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }
}
