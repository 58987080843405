<div class="app-header-left">
  <ul [ngStyle]="{ opacity: value ? '0' : '1' }" class="header-megamenu nav">
    <li class="nav-item">
      <button
        [autoClose]="true"
        [ngbPopover]="analytics"
        class="btn btn-link dropdown-toggle"
        placement="bottom-left"
        popoverClass="rm-max-width"
        type="button">
        <span>
          <i class="nav-link-icon pe-7s-graph2"></i>
          Аналитика
        </span>
      </button>
    </li>
    <li class="nav-item">
      <button
        [autoClose]="true"
        [ngbPopover]="advertising"
        class="btn btn-link dropdown-toggle"
        placement="bottom-left"
        popoverClass="rm-max-width"
        type="button">
        <span>
          <i class="nav-link-icon pe-7s-graph2"></i>
          Реклама
        </span>
      </button>
    </li>
    <li class="nav-item">
      <button
        [autoClose]="'true'"
        [ngbPopover]="finances"
        class="btn btn-link dropdown-toggle"
        placement="bottom-left"
        popoverClass="rm-max-width"
        type="button">
        <span>
          <i class="nav-link-icon fa fa-money"></i>
          Финансы
        </span>
      </button>
    </li>
    <li class="nav-item">
      <button
        [autoClose]="'true'"
        [ngbPopover]="settings"
        class="btn btn-link dropdown-toggle"
        placement="bottom-left"
        popoverClass="rm-min-width"
        type="button">
        <span>
          <i class="nav-link-icon pe-7s-home"></i>
          Настройки
        </span>
      </button>
    </li>
    <li class="nav-item">
      <button
        [autoClose]="'true'"
        [ngbPopover]="profile"
        class="btn btn-link dropdown-toggle"
        placement="bottom-left"
        popoverClass="rm-min-width"
        type="button">
        <span>
          <i class="nav-link-icon pe-7s-settings"></i>
          Профиль
        </span>
      </button>
    </li>
    <ng-container *ngIf="isAdmin">
      <li class="nav-item">
        <button
          [autoClose]="'true'"
          [ngbPopover]="headerMegaPopover5"
          class="btn btn-link dropdown-toggle"
          placement="bottom-left"
          popoverClass="rm-min-width"
          type="button">
          <span>
            <i class="nav-link-icon pe-7s-home"></i>
            Админ
          </span>
        </button>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #analytics>
  <ul class="nav flex-column">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/rates">
        <span>Общие показатели</span>
      </a>
    </li>
    <li *ngIf="!isOzon" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/rnp">
        <span>РНП (Воронка)</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/abc">
        <span>АВС анализ</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/orders">
        <span>Мои заказы</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/sales">
        <span>Мои продажи</span>
      </a>
    </li>
    <li *ngIf="!isOzon" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/heatmap">
        <span>Тепловая карта</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/clusters">
        <span>Кластеры</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard/remains">
        <span>Остатки</span>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #advertising>
  <ul class="nav flex-column">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/adv/company">
        <span>ДРР</span>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #finances>
  <ul class="nav flex-column">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/pnl">
        <span>ОПиУ (PnL)</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/pnl-consolidation">
        <span>PnL консолидация</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/dds">
        <span>ДДС (CashFlow)</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/operations">
        <span>Операции</span>
      </a>
    </li>
    <li *ngIf="!isOzon" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/reports/reconciliation">
        <span>Сверка с ЛК</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/guides/products">
        <span>Себестоимость</span>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #settings>
  <ul class="nav flex-column">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/guides/products">
        <span>Мои товары</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/settings/partners">
        <span>Контрагенты</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/guides/buyouts">
        <span>Самовыкупы</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/settings/items">
        <span>Статьи</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/fin/settings/accounts">
        <span>Счета</span>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #profile>
  <ul class="nav flex-column">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/settings/shop">
        <span>Кабинеты</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/profile/users">
        <span>Пользователи</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/profile/tariff">
        <span>Тарифы</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/profile/bill">
        <span>Мои счета</span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/profile/partners">
        <span>Партнерская программа</span>
      </a>
    </li>
  </ul>
</ng-template>