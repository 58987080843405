import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OAuthGuard {
  canActivate(): boolean {
    const profilePhone = localStorage.getItem('profilePhone');

    if(!!localStorage.getItem('hasBrokenToken') && JSON.parse(profilePhone) !== "+79032632693"){
      return false;
    }
    return true;
  }
}

