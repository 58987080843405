import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cssBackgroundPipe'
})
export class CssBackgroundPipe implements PipeTransform {

  transform(value: number | string | null | { value: number, deltaInRubles: number, deltaInPercent: number }, selectedUnitType: number): string | null {

    if(typeof value === 'object' && !!value) {
      if(selectedUnitType === 1){
        switch (true) {
          case typeof value.deltaInRubles === 'object' && !value.deltaInRubles:
            return '#e6babe';
          case +value.deltaInRubles >= 0:
            return '#bce9de';
          case +value.deltaInRubles < 0:
            return '#fceed1';
          default:
            return null
        }
      }
  
      else {
        switch (true) {
          case typeof value.deltaInPercent === 'object' && !value.deltaInPercent:
            return '#e6babe';
          case +value.deltaInPercent >= 0:
            return '#bce9de';
          case +value.deltaInPercent < 0:
            return '#fceed1';
          default:
            return null
        }
      }
    }
    else {
      return null
    }

  }
}
