import { Component } from '@angular/core';

@Component({
  selector: 'app-login-slider',
  templateUrl: './login-slider.component.html',
  styleUrls: ['./login-slider.component.scss']
})
export class LoginSliderComponent {
  slideConfig2 = {
    className: 'center',
    centerMode: true,
    slidesToScroll: 1,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 2000,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000
  };
}
