<div class="slider-light">
  <div class="slider-intro">
    <span class="slider-intro-lg">MP SURF</span>
  </div>
  <ngx-slick-carousel
    class="slick-slider slick-dotted"
    #slickModal="slick-carousel"
    [config]="slideConfig2"
  >
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg">
        </div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-01.png" alt="Финансовая отчетность">
          <h5>Финансовая отчетность</h5>
          <h6>Анализируйте доходы, расходы и чистую прибыль вашего бизнеса. Получайте наглядные отчеты для контроля эффективности и принятия обоснованных решений.</h6>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg"></div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-02.png" alt="Финансовая отчетность">
          <h5>Воронка продаж</h5>
          <h6>Оценивайте этапы пути клиента от перехода в карточку до покупки. Улучшайте конверсии, следите за динамикой важных метрик (% выкупа, ДРР и пр.) и увеличивайте прибыль.</h6>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg opacity-6"></div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-03.png" alt="Финансовая отчетность">
          <h5>Аналитика продаж и заказов</h5>
          <h6>Следите за динамикой продаж и заказов, анализируйте популярные товары и корректируйте стратегии. Увеличьте доходы, понимая потребности клиентов.</h6>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div
        class="position-relative h-100 d-flex justify-content-center align-items-center">
        <div class="slide-img-bg opacity-6"></div>
        <div class="slider-content text-light">
          <img class="slider-image" src="assets/images/carousel/mpSurf-slide-04.png" alt="Финансовая отчетность">
          <h5>Управленческие решения</h5>
          <h6>Принимайте продуманные решения на основе точных данных. Оптимизируйте процессы, минимизируйте затраты и повышайте рентабельность бизнеса.</h6>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
