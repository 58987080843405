import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { ABC_EXPORT_COLUMNS, generateColumnDefs } from './abs-analyst.mock';
import { LOCALE_TEXT_FOR_FILTERS } from '../../guides/products/products.mock';
import { ColDef, DragStoppedEvent, GridReadyEvent, RowNode } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { ICardWidget } from '../main-test/main-test.interface';
import { generateCardData, generateSummary } from './abc-analyst.func';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';
import { IStatus } from '../../guides/products/products.interface';
import { PopUpMessages } from 'app/shared/mocks/pop-up-messages.mock';
import { DOCUMENT } from '@angular/common';
import { IAbcRowExport } from './abs-analyst.interface';
import {
  CDK_DRAG_CONFIG,
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-abc-analyst',
  templateUrl: './abc-analyst.component.html',
  styleUrls: ['./abc-analyst.component.scss'],
  providers: [MessageService, ConfirmationService, { provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbcAnalystComponent extends AbstractMenuTabComponent implements OnInit {
  
  isLoading = false;
  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(30, 'day'),
    endDate: moment(),
  };

  selectedAnaliseStatus = 1;
  selectedIndicator: number;
  selectedArticleBarcode: number;
  selectedWbParams: number;
  lastReportDate = ''
  summaryData = []
  text = ''
  subtext = ''
  otherDeductionsData: ICardWidget | null = null
  isOzon = false;
  checked = true;
  wbChecked = false;
  statusesList = [];
  categoriesList = [];
  selectedStatuses = [];
  selectedCategories = [];
  columnDefs = []

  fboFbsChecked = false;

  isFree = false;

  rowDataGeneral = null;
  cards: ICardWidget[] = [];
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    comparator: NumberSortFunc
  };

  ABC_DATA = null
  tariff = ''
  isAllowed = 0;

  general_categories = (() => {
      if(!localStorage.getItem('abc_settings')) {
        return [
          {name: 'Фото', key: 'photo_new', checked: true, disabled: true, dragDisabled: true},
          {name: 'Артикул МП', key: 'nmID', checked: true, disabled: false, dragDisabled: true}, 
          {name: 'Предмет', key: 'object', checked: true, disabled: false, dragDisabled: true}, 
          {name: 'Артикул поставщика', key: 'sku', checked: true, disabled: false, dragDisabled: true}, 
          {name: 'ABC', key: 'abc', checked: true, disabled: false, dragDisabled: true},
        ]
      }
      const parsed: any[] = JSON.parse(localStorage.getItem('abc_settings'));

      return parsed.slice(0, 5)

  })();

  abc1_categories = (() => {
    if(!localStorage.getItem('abc_settings')) {
      return [
        {name: 'Выручка, руб.', key: 'sales', checked: true, disabled: false, dragDisabled: false}, 
        {name: 'Продажи, шт', key: 'sales_count', checked: true, disabled: false, dragDisabled: false}, 
        {name: '% от выручки', key: 'percent_sales', checked: true, disabled: false, dragDisabled: false}, 
        {name: 'Заказы, руб.', key: 'orders_sum', checked: true, disabled: false, dragDisabled: false},
        {name: 'Заказы, шт.', key: 'orders', checked: true, disabled: false, dragDisabled: false}, 
        {name: 'Продано из заказанных', key: 'sales_from_orders', checked: true, disabled: false, dragDisabled: false}, 
        {name: '% выкупа (по доехавшим)', key: 'percent_sales_delivered', checked: true, disabled: false, dragDisabled: false}, 
        {name: '% выкупа (по заказанным)', key: 'percent_sales_redeemed', checked: true, disabled: false, dragDisabled: false},
      ]
    }
    const parsed: any[] = JSON.parse(localStorage.getItem('abc_settings'));
    return parsed.slice(5, 13)
  })();

  abc2_categories = (() => {
    if(!localStorage.getItem('abc_settings')) {
      return [
        {name: 'Валовая прибыль, руб', key: 'profit', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Валовая прибыль, %', key: 'percent_profit', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Рентабельность товара по с/с, %', key: 'profitability', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Себес-ть (закупка), руб.', key: 'cost_price', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Логистика, руб', key: 'logistic', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Комиссия ВБ, руб', key: 'commission', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Эквайринг', key: 'acquiring', checked: true, disabled: false, dragDisabled: false}, //
        {name: 'ДРР по заказам, %', key: 'drr', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Реклама внутренняя', key: 'adv', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Реклама внешняя', key: 'adv_external', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Маркетинг', key: 'marketing', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Прочие удержания', key: 'other_deductions', checked: true, disabled: false, dragDisabled: false},//
      ]
    }
    const parsed: any[] = JSON.parse(localStorage.getItem('abc_settings'));
    return parsed.slice(13, 25)
  })();

  abc3_categories = (() => {
    if(!localStorage.getItem('abc_settings')) {
      return [
        {name: 'Оборачиваемость по заказам, дней.', key: 'turnover', checked: true, disabled: false, dragDisabled: false}, 
        {name: 'Оборачиваемость по продажам, дней', key: 'turnover_sales', checked: true, disabled: false, dragDisabled: false}, 
        {name: 'Среднее кол-во заказов в день на сегодня', key: 'avg_orders_day', checked: true, disabled: false, dragDisabled: false}, 
        {name: 'Остатки FBO', key: 'total_stocks', checked: true, disabled: false, dragDisabled: false},
      ]
    
    }
    const parsed: any[] = JSON.parse(localStorage.getItem('abc_settings'));
    return parsed.slice(25, 29)
  })();

  unit_categories = (() => {
    if(!localStorage.getItem('abc_settings')) {
      return [
        {name: 'Ср. цена продажи, руб', key: 'unit_avg_sale_sum', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Прибыль на ед., руб', key: 'unit_profit_per', checked: true, disabled: false, dragDisabled: false},//
        {name: '% от прибыли', key: 'unit_percent_profit', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Итого затрат на ед., руб.', key: 'total_costs_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Итого затрат на ед., %', key: 'total_costs_unit_per', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Себестоимость на ед., руб.', key: 'unit_cost_price_per', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Себестоимость на ед., % ', key: 'unit_percent_cost_price_per', checked: true, disabled: false, dragDisabled: false}, //
        {name: 'Комиссия на ед., руб.', key: 'commission_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Комиссия на ед., %', key: 'commission_unit_per', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Логистика на ед., руб.', key: 'logistic_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Логистика на ед., %', key: 'logistic_unit_per', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Эквайринг на ед., руб.', key: 'acquiring_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Реклама внеш. на ед., руб', key: 'adv_external_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Реклама внутр. на ед., руб', key: 'adv_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Маркетинг на ед., руб.', key: 'marketing_unit_rub', checked: true, disabled: false, dragDisabled: false},//
        {name: 'Прочие удержания на ед., руб', key: 'other_deductions_unit_rub', checked: true, disabled: false, dragDisabled: false},//
      ]
    
    }
    const parsed: any[] = JSON.parse(localStorage.getItem('abc_settings'));
    return parsed.slice(29)
  })();
  

  categories: any[] = [];

  dropAbc1(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.abc1_categories, event.previousIndex, event.currentIndex);
  }

  dropAbc2(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.abc2_categories, event.previousIndex, event.currentIndex);
  }

  dropAbc3(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.abc1_categories, event.previousIndex, event.currentIndex);
  }

  dropUnit(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.unit_categories, event.previousIndex, event.currentIndex);
  }

  @ViewChild('agGrid', {read: ElementRef}) agGrid: ElementRef | null = null

  constructor(@Inject(DOCUMENT) private readonly _document: Document, private readonly _renderer: Renderer2){
    super()
    this.gridOptions = {
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    this.columnDefs = generateColumnDefs(this.wbChecked, this.fboFbsChecked)
    this.isOzon = JSON.parse(localStorage.getItem('mpall_shop')).marketPlace === 2;
    this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
      this.lastReportDate = data.last_data;
    })
    this.loadStatuses();
    this.loadCategories();
    this._mpSurfService
      .loadMetaData(this.SHOP_ID, this.TOKEN)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        //@ts-ignore
        const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/abc') || { die_text: null, die_title: null }
        this.text = die_title
        this.subtext = die_text
      })

    this.isExcelAllowed$.subscribe((isAllowed) => {
      this.isAllowed = isAllowed
    })
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  private loadStatuses(): void {
    this._mpSurfService
      .load({}, 'products-status')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: IStatus[]) => {
          this.statusesList = response;
        },
    );
  }

  private loadCategories(): void {
    this._mpSurfService
      .load({}, 'products/category-pnl-list')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: string[]) => {
          this.categoriesList = response.map(item => ({name: item}));
        },
    );
  }

  loadAbsAnalyst() {
    const params = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
      adv_oper: this.checked ? '1' : '0',
      wb_oper: this.wbChecked ? '1' : '0',
      fbo_fbs: this.fboFbsChecked ? '1' : '0',
      myStatus: Array.isArray(this.selectedStatuses) ?  this.selectedStatuses.map(s => s.name) : [],
      categoryPnl: Array.isArray(this.selectedCategories) ? this.selectedCategories.map(s => s.name) : []
    }
    this._mpSurfService.loadByPost(params, 'abc/list-v2')
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        
        this.gridApi.setFilterModel(null)

        this.ABC_DATA = data;
        //@ts-ignore
        this.rowDataGeneral = data.data;
        //@ts-ignore
        this.otherDeductionsData = data.otherDeductions
        //@ts-ignore
        this.cards = generateCardData(data.data);
        //@ts-ignore
        this.summaryData = [{...data.total, photo_new: 'empty', nmID: 'Итого'}];
        this._cdr.markForCheck();
      })
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
    this.loadAbsAnalyst()
  }

  onCheckboxChange(event: {checked: boolean}): void {
    this.checked = event.checked
    this.loadAbsAnalyst()
  }

  onClearStatuses(): void {
    this.selectedStatuses = [];
    this.loadAbsAnalyst();
  };

  onClearCategories(): void {
    this.selectedCategories = [];
    this.loadAbsAnalyst();
  }

  onGridFilterChanged(): void {
    const rows = [];
    this.gridApi.forEachNodeAfterFilter((node : RowNode) => {
      rows.push(node.data)
    })
    this.summaryData = generateSummary(rows);
  }

  selectStatus(): void {
    this.loadAbsAnalyst();
  }

  selectCategory(): void {
    this.loadAbsAnalyst();
  }

  exportExcel() {

    if(!this.isAllowed) return;

    this._mpSurfService
      .loadByPost(this.ABC_DATA, `abc/export`)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any) => {
          this.importXlsx(response);
        },
        () => {
          this.showPopUpMessage(
            'error',
            PopUpMessages.loadFailedSummary,
            PopUpMessages.loadFailedMessages
          );
        }
      );
  }

  private async importXlsx(excelData: IAbcRowExport[]): Promise<void> {

    if(this.isFree) return;

    const ExcelJS = await import('exceljs');

    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet(`ABC`);
    workSheet.columns = ABC_EXPORT_COLUMNS;

    excelData.forEach(dataItem => {
      workSheet.addRow({
        "Артикул МП": dataItem['Артикул МП'],
        "Артикул поставщика": dataItem['Артикул поставщика'],
        "Предмет": dataItem['Предмет'],
        "ABC": dataItem['ABC'],
        "Продажи (без вычета СПП), руб.": +dataItem['Продажи (без вычета СПП), руб.'],//+
        "% от выручки ": dataItem['% от выручки '],
        "Заказы (всего)": +dataItem['Заказы (всего)'],//+
        "Продано из заказанных": +dataItem['Продано из заказанных'],//+
        "Продажи, шт": +dataItem['Продажи, шт'],//+
        "% выкупа (по заказанным)": dataItem['% выкупа (по заказанным)'],
        "Прибыль, руб": +dataItem['Прибыль, руб'],//+
        "Прибыльность, %": dataItem['Прибыльность, %'],
        "Рентабельность товара по с/с, %": dataItem['Рентабельность товара по с/с, %'],
        "Себес-ть (закупка), руб.": +dataItem['Себес-ть (закупка), руб.'],//+
        "Логистика, руб": +dataItem['Логистика, руб'],//+
        "Комиссия ВБ, руб": +dataItem['Комиссия ВБ, руб'],//+
        "Эквайринг": +dataItem['Эквайринг'],//+
        "Реклама и маркетинг суммарно": +dataItem['Реклама и маркетинг суммарно'],//+
        "Прочие удержания": +dataItem['Прочие удержания'],//+
        "Оборачиваемость, дней": +dataItem['Оборачиваемость, дней'],
        "Среднее кол-во заказов в день на сегодня": +dataItem['Среднее кол-во заказов в день на сегодня'],//+
        "Остатки TOTAL": +dataItem['Остатки TOTAL'],//+
        "Ср. цена продажи, руб": +dataItem["Ср. цена продажи, руб"],
        "Прибыль на ед., руб": +dataItem["Прибыль на ед., руб"],
        "% от прибыли": +dataItem["% от прибыли"],
        "Итого затрат на ед., руб.": +dataItem["Итого затрат на ед., руб."],
        "Себестоимость на ед., руб.": +dataItem["Себестоимость на ед., руб."],
        "Себестоимость на ед., %": +dataItem["Себестоимость на ед., %"],
        "Комиссия на ед., руб.": +dataItem["Комиссия на ед., руб."],
        "Комиссия на ед., %": +dataItem["Комиссия на ед., %"],
        "Логистика на ед., %": +dataItem["Логистика на ед., %"],
        "Эквайринг на ед., руб.": +dataItem["Эквайринг на ед., руб."],
        "Реклама внеш. на ед., руб": +dataItem["Реклама внеш. на ед., руб"],
        "Реклама внутр. на ед., руб": +dataItem["Реклама внутр. на ед., руб"],
        "Маркетинг на ед., руб.": +dataItem["Маркетинг на ед., руб."],
        "Прочие удержания на ед., руб": +dataItem["Прочие удержания на ед., руб"],
      })
    })

    const file = await workbook.xlsx.writeBuffer();
    const fileName = `ABC_export_${new Date().getTime()}.xls`;

    const blob = new Blob([file], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    FileSaver.saveAs(
      blob,
      fileName 
    );
  }

  onDragStopped(event: DragStoppedEvent) {
    // localStorage.setItem('abc-column-defs', JSON.stringify(event.api.getColumnDefs()))
  }

  onWbCheckboxChange(event: {checked: boolean}): void {
    this.wbChecked = event.checked;
    this.gridApi.setColumnDefs(generateColumnDefs(this.wbChecked, this.fboFbsChecked))
    this.loadAbsAnalyst();
  }

  onFboFbsCheckboxChange(event: {checked: boolean}): void {
    this.fboFbsChecked = event.checked;
    this.gridApi.setColumnDefs(generateColumnDefs(this.wbChecked, this.fboFbsChecked))
    this.loadAbsAnalyst();
  }

  openCustomizator(content: TemplateRef<any>) {
    this._modalService.open(content, {
      centered: true,
      windowClass: 'customizator-modal'
    });

    const dialogRef = this._document.querySelector('.modal-dialog');

    if (dialogRef) {
      this._renderer.setStyle(dialogRef, 'box-shadow', 'none');
      this._renderer.setStyle(dialogRef, 'max-width', '720px');
    }
  }

  onCheckboxesChangeGeneral(event: any, key: string): void {
    this.general_categories = this.general_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesChangeAbc1(event: any, key: string): void {
    this.abc1_categories = this.abc1_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesChangeAbc2(event: any, key: string): void {
    this.abc2_categories = this.abc2_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesChangeAbc3(event: any, key: string): void {
    this.abc3_categories = this.abc3_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesChangeUnit(event: any, key: string): void {
    this.unit_categories = this.unit_categories.map(ctg => {
      return ctg.key === key ? { ...ctg, checked: event.checked } : ctg;
    })
  }

  onCheckboxesReset(): void {
    this.general_categories = this.general_categories.map((ctg, index )=> ({...ctg, checked: index === 0 ? true : false}));
    this.abc1_categories = this.abc1_categories.map((ctg, index )=> ({...ctg, checked: false}));
    this.abc2_categories = this.abc2_categories.map((ctg, index )=> ({...ctg, checked: false}));
    this.abc3_categories = this.abc3_categories.map((ctg, index )=> ({...ctg, checked: false}));
    this.unit_categories = this.unit_categories.map((ctg, index )=> ({...ctg, checked: false}));
  }

  onCheckboxesAll(): void {
    this.general_categories = this.general_categories.map((ctg, index )=> ({...ctg, checked: true}));
    this.abc1_categories = this.abc1_categories.map((ctg, index )=> ({...ctg, checked: true}));
    this.abc2_categories = this.abc2_categories.map((ctg, index )=> ({...ctg, checked: true}));
    this.abc3_categories = this.abc3_categories.map((ctg, index )=> ({...ctg, checked: true}));
    this.unit_categories = this.unit_categories.map((ctg, index )=> ({...ctg, checked: true}));
  }

  onCheckboxesSave(): void {
    this.categories = [
      ...this.general_categories,
      ...this.abc1_categories,
      ...this.abc2_categories,
      ...this.abc3_categories,
      ...this.unit_categories
    ];
    localStorage.setItem('abc_settings', JSON.stringify(this.categories));
    window.location.reload()
  }
}