import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractMenuTabComponent } from '../../../menu-tabs/abstract-menu-tab.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { THIRD_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import {
  IFullTableItem,
  IGeneralInfo,
  IGeneralInfoTableItem,
  IPlanFactArticle,
  IPlanFactRowData,
} from './plan-fact.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MODAL_COLUMNS_DEFS, PLAN_FACT_COLUMN_DEFS } from './plan-fact.model';
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICardWidget } from '../main-test/main-test.interface';
import { ApexOptions } from 'ng-apexcharts';
import { getChartOptions, getDateKeys, mapToRows } from './plan-fact.func';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { timer } from 'rxjs';
import { LOCALE_TEXT_FOR_FILTERS } from 'app/menu-tabs/guides/products/products.mock';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-plan-fact',
  templateUrl: './plan-fact.component.html',
  styleUrls: ['./plan-fact.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class PlanFactComponent
  extends AbstractMenuTabComponent
  implements OnInit
{
  public dataSource = new MatTableDataSource<any>([]);

  columns: any[];
  displayedColumns: string[];
  displayedColumns2: string[];
  groupByColumns: string[] = [];
  allData: any[];
  _allGroup: any[];

  expandedCar: any[] = [];
  expandedSubCar: any[] = [];

  @ViewChild(MatSort) sort: MatSort;

  readonly presets = THIRD_RANGE_TYPE;

  plan_facts: IPlanFactRowData[] = [];

  plan_fact: IPlanFactRowData & { articles: IPlanFactArticle[] } = null;

  generalInfoMetadata?: Partial<IGeneralInfo>;

  generalInfoTable?: IGeneralInfoTableItem;

  mappedData = [];

  chartOptions: Partial<ApexOptions> | null = null;

  goal = null;

  articles = []

  groups = []

  readonly defaultColDef: ColDef = {
    sortable: true,
    minWidth: 40,
    filter: true,
    headerClass: 'header-centered',
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };

  readonly tooltipShowDelay = 0;
  readonly tooltipHideDelay = 2000;

  columnDefs = PLAN_FACT_COLUMN_DEFS;
  modalColumnDefs = MODAL_COLUMNS_DEFS;

  modalRowData = [];

  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;

  protected gridOptionsPlanFact: GridOptions | null = null;

  createPlanFormGroup?: FormGroup;
  updatePlanFormGroup?: FormGroup;

  selectedMethodWhenCreate = 1;
  selectedMethodWhenUpdate = 1;

  methods = [
    { code: 1, name: 'По кабинету' },
    { code: 2, name: 'По артикулу' },
  ];

  types = [
    { code: 1, name: 'По сумме заказов' },
    { code: 2, name: 'По сумме продаж' },
  ];

  cards: ICardWidget[] = [];

  articlesFromBackend: IPlanFactArticle[] = [];

  articlesFromPlan: IPlanFactArticle[] = [];

  editMode = false;


  dateKeys = [];

  selectedDateType = 1;
  selectedUnitType = 1

  planId = 0;

  start = '';

  dateColumns = [];

  filteredColumns = [];

  numToMonthsMap: Map<string, string> = new Map()
  .set('01', 'янв.')
  .set('02', 'февр.')
  .set('03', 'мар.')
  .set('04', 'апр.')
  .set('05', 'май')
  .set('06', 'июн.')
  .set('07', 'июл.')
  .set('08', 'авг.')
  .set('09', 'сент.')
  .set('10', 'окт.')
  .set('11', 'нояб.')
  .set('12', 'дек.')

  monthToNumsMap: Map<string, string> = new Map()
  .set('янв.', '01')
  .set('февр.', '02')
  .set('мар.', "03")
  .set('апр.', '04')
  .set('май', '05')
  .set('июн.', '06')
  .set('июл.', '07')
  .set('авг.', '08')
  .set('сент.', '09')
  .set('окт.', '10')
  .set('нояб.', '11')
  .set('дек.', '12')

  rangeStartDateItems = moment([new Date().getUTCFullYear(), new Date().getUTCMonth()]).startOf('month').format('MM/YYYY').split('/');
  rangeStartDate = `${this.numToMonthsMap.get(this.rangeStartDateItems[0])} ${this.rangeStartDateItems[1]}`

  selectedFromYear = this.rangeStartDateItems[1]
  selectedFromMonth = this.rangeStartDateItems[0]

  years = [
    {name: '2024', key: '2024'},
    {name: '2025', key: '2025'},
    {name: '2026', key: '2026'},
    {name: '2027', key: '2027'},
  ];
  months = [
    {name: 'Январь', key: '01'}, 
    {name: 'Февраль', key: '02'}, 
    {name: 'Март', key: '03'}, 
    {name: 'Апрель', key: '04'},
    {name: 'Май', key: '05'}, 
    {name: 'Июнь', key: '06'}, 
    {name: 'Июль', key: '07'}, 
    {name: 'Август', key: '08'},
    {name: 'Сентябрь', key: '09'}, 
    {name: 'Октябрь', key: '10'}, 
    {name: 'Ноябрь', key: '11'}, 
    {name: 'Декабрь', key: '12'},
  ];

  @ViewChild('createFormTpl') createFormTpl: TemplateRef<unknown>;
  @ViewChild('updateFormTpl') updateFormTpl: TemplateRef<unknown>;

  constructor(private readonly _fb: FormBuilder) {
    super();
    this.gridOptionsPlanFact = {
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    this.loadPlanFacts();
    this.createPlanFormGroup = this._fb.group({
      plan_name: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      dateFrom: new FormControl(this.rangeStartDate, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      yearFrom: new FormControl(this.selectedFromYear, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      monthFrom: new FormControl(this.selectedFromMonth, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      goal_rub: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: new FormControl(1, { nonNullable: true }),
      method: new FormControl(1, { nonNullable: true }),
      articles: new FormArray([]),
    });

    this.updatePlanFormGroup = this._fb.group({
      plan_name: new FormControl(null, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      dateFrom: new FormControl(this.rangeStartDate, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      yearFrom: new FormControl(this.selectedFromYear, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      monthFrom: new FormControl(this.selectedFromMonth, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      goal_rub: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: new FormControl(1, { nonNullable: true }),
      method: new FormControl(1, { nonNullable: true }),
      articles: new FormArray([]),
    });

    this.subscribeOnMethodWhenCreate();
    this.subscribeOnArticlesWhenCreate();

    this.subscribeOnMethodWhenUpdate();
    this.subscribeOnArticlesWhenUpdate();

    this.subscribeOnMonthFromChange();
    this.subscribeOnYearFromChange();
  }

  subscribeOnMonthFromChange(): void {
    this.createPlanFormGroup.controls['monthFrom'].valueChanges
    .pipe(untilDestroyed(this))
      .subscribe(month => {
        const selectedYear = this.createPlanFormGroup.controls['yearFrom'].value;
        this.createPlanFormGroup.controls['dateFrom'].setValue(`${this.numToMonthsMap.get(month)} ${selectedYear}`);
      })

    this.updatePlanFormGroup.controls['monthFrom'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(month => {
        const selectedYear = this.updatePlanFormGroup.controls['yearFrom'].value;
        this.updatePlanFormGroup.controls['dateFrom'].setValue(`${this.numToMonthsMap.get(month)} ${selectedYear}`);
      })
  }

  subscribeOnYearFromChange(): void {
    this.createPlanFormGroup.controls['yearFrom'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(year => {
        const selectedMonth = this.numToMonthsMap.get(this.createPlanFormGroup.controls['monthFrom'].value);
        this.createPlanFormGroup.controls['dateFrom'].setValue(`${selectedMonth} ${year}`);
      })
    this.updatePlanFormGroup.controls['yearFrom'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(year => {
        const selectedMonth = this.numToMonthsMap.get(this.updatePlanFormGroup.controls['monthFrom'].value);
        this.updatePlanFormGroup.controls['dateFrom'].setValue(`${selectedMonth} ${year}`);
      })
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  onRowClick(id: number, start: string): void {
    this.planId = id;
    this.start = start;
    this.loadCards(id);
    this.loadGeneralInfo(id);
    this.loadChart(id);
    this.loadFullTable(id, start);
  }

  onGoalInputWhenCreate(event: {
    originalEvent: KeyboardEvent;
    value: number;
  }): void {
    this.createPlanFormGroup.controls['goal_rub'].setValue(event.value);
  }

  onGoalInputWhenUpdate(event: {
    originalEvent: KeyboardEvent;
    value: number;
  }): void {
    this.updatePlanFormGroup.controls['goal_rub'].setValue(event.value);
  }

  onEditClick(id: number): void {
    this.editMode = true;
    this.loadPlanFactById(id);
  }

  onCloneClick(id: number): void {
    this.loadPlanFactById(id, true);
  }

  onDeleteClick(id: number): void {
    this.deletePlanRequest(id);
  }

  onDateTypeChange(): void {
    this.loadFullTable(this.planId, this.start);
  }

  openCreatePlanModal(formTpl: TemplateRef<unknown>): void {
    this.articlesFromBackend = [];
    this.createPlanFormGroup.reset();
    this._modalService.open(formTpl, { centered: true, modalDialogClass: 'mw-1500' });
  }

  submitCreate(): void {
    const body = this.createPlanFormGroup.getRawValue();

    if (this.selectedMethodWhenCreate === 2) {
      body['articles'] = this.gridApi.getSelectedRows();
    } else {
      delete body.articles;
    }
    if (typeof body.start_date === 'object') {
      const { day, month, year } = body.start_date as {
        day: number;
        month: number;
        year: number;
      };
      body.start_date = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
    this.createPlanRequest(body);
  }

  submitUpdate(): void {
    const body = this.updatePlanFormGroup.getRawValue();

    if (this.selectedMethodWhenUpdate === 2) {
      body['articles'] = this.gridApi.getSelectedRows();
    } else {
      delete body.articles;
    }
    if (typeof body.start_date === 'object') {
      const { day, month, year } = body.start_date as {
        day: number;
        month: number;
        year: number;
      };
      body.start_date = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
    this.updatePlanRequest(body);
  }

  private loadPlanFacts(): void {
    this._mpSurfService
      .load({}, 'plan-fact')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (
          data: IPlanFactRowData[] | { is_error: number; msg: string }
        ) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.plan_facts = data as IPlanFactRowData[];
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadPlanFactById(id: number, cloning = false): void {
    this._mpSurfService
      .load({}, `plan-fact/${id}`)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IPlanFactRowData & { articles: IPlanFactArticle[] } | { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.planId = data['id'];
            this.plan_fact = data as IPlanFactRowData & {
              articles: IPlanFactArticle[];
            };
            //@ts-ignore
            this.modalRowData = data.articles as IPlanFactArticle[] 
            if (!cloning) {
              this.populateForm(this.plan_fact);
              this._modalService.open(this.updateFormTpl, { centered: true, modalDialogClass: 'mw-1500' });
            } else {
              this.createPlanRequest(this.plan_fact);
            }
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private subscribeOnMethodWhenCreate(): void {
    this.createPlanFormGroup.controls['method'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedMethodWhenCreate = value;
        if (this.selectedMethodWhenCreate === 2) {
          this.loadArticlesForForm();
        }
      });
  }

  private subscribeOnMethodWhenUpdate(): void {
    this.updatePlanFormGroup.controls['method'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedMethodWhenUpdate = value;
        if (this.selectedMethodWhenUpdate === 2) {
          this.articlesFromPlan = this.plan_fact.articles;
        }
      });
  }

  private subscribeOnArticlesWhenCreate(): void {
    this.createPlanFormGroup.controls['articles'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(
        (values: { remove_from_plan: boolean; plan_sum: number }[]) => {
          const agg = values
            .filter(value => !value.remove_from_plan)
            .reduce((acc, curr) => acc + curr.plan_sum, 0);
          this.createPlanFormGroup.controls['goal_rub'].setValue(agg);
        }
      );
  }

  private subscribeOnArticlesWhenUpdate(): void {
    this.updatePlanFormGroup.controls['articles'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(
        (values: { remove_from_plan: boolean; plan_sum: number }[]) => {
          const agg = values
            .filter(value => !value.remove_from_plan)
            .reduce((acc, curr) => acc + curr.plan_sum, 0);
          this.updatePlanFormGroup.controls['goal_rub'].setValue(agg);
        }
      );
  }

  private loadArticlesForForm(): void {
    this._mpSurfService
      .load({}, 'plan-fact/articles')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (
          data: IPlanFactArticle[] | { is_error: number; msg: string }
        ) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.modalRowData = data as IPlanFactArticle[];
            this._cdr.markForCheck()
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadCards(id: number): void {
    this._mpSurfService
      .load({ id }, 'plan-fact/cards')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: ICardWidget[] | { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.cards = data as ICardWidget[];
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadGeneralInfo(id: number): void {
    this._mpSurfService
      .load({ id }, 'plan-fact/general-info')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IGeneralInfo | { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.generalInfoMetadata = data as IGeneralInfo;
            this.generalInfoTable = (data as IGeneralInfo).table;
            this.mappedData = mapToRows(this.generalInfoTable);
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadChart(id: number): void {
    this._mpSurfService
      .load({ id }, 'plan-fact/chart')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (
          data: Partial<ApexOptions> | { is_error: number; msg: string }
        ) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.chartOptions = getChartOptions(data as Partial<ApexOptions>);
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private createPlanRequest(
    body: IPlanFactRowData & { articles: IPlanFactArticle[] }
  ): void {
    this._mpSurfService
      .loadByPost(body, 'plan-fact')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this._modalService.dismissAll();
            this.showPopUpMessage('success', '', data['msg']);
            this.loadPlanFacts();
          }
        },
        error: err => {},
      });
  }

  private updatePlanRequest(
    body: IPlanFactRowData & { articles: IPlanFactArticle[] }
  ): void {
    body['id'] = this.planId;
    this._mpSurfService
      .loadByPut(body, 'plan-fact')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this._modalService.dismissAll();
            this.showPopUpMessage('success', '', data['msg']);
            this.loadPlanFacts();
          }
        },
        error: err => {},
      });
  }

  private deletePlanRequest(id: number): void {
    this._mpSurfService
      .delete({}, 'plan-fact/' + id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: { is_error: number; msg: string }) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
            this.loadPlanFacts();
          } else {
            this._modalService.dismissAll();
            this.showPopUpMessage('success', '', data['msg']);
            this.loadPlanFacts();
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private loadFullTable(id: number, start: string): void {
    const params = { id, date_type: this.selectedDateType };

    if (this.selectedDateType === 1) {
      params['month'] = +start;
    }

    this._mpSurfService
      .load(params, 'plan-fact/full-table')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: IFullTableItem[]) => {
          if (data['is_error']) {
            this.showPopUpMessage('error', 'Ошибка', data['msg']);
          } else {
            this.dataSource.data = data;
            this.groups = data;
            this.dateColumns = [
              'groupHeader',
              'plan_day',
              'total',
              ...getDateKeys(data as unknown as IFullTableItem[]),
            ];
            this.filteredColumns = getDateKeys(data);
            this.columns = [
              {
                field: 'type',
              },
              {
                field: 'plan_day',
              },
              {
                field: 'total',
              },
              ...this.filteredColumns.map(col => ({field: col}))
            ];
            this.displayedColumns2 = this.columns.map(column => column.field === 'type' ? 'typeHeader' : column.field);
            this.displayedColumns = this.columns.map(column => column.field);
          }
        },
        error: err => this.showPopUpMessage('error', 'Ошибка', ''),
      });
  }

  private populateForm(
    data: IPlanFactRowData & { articles: IPlanFactArticle[] }
  ): void {
    this.updatePlanFormGroup.controls['plan_name'].setValue(data.plan_name);
    this.updatePlanFormGroup.controls['goal_rub'].setValue(data.goal_rub);
    this.updatePlanFormGroup.controls['type'].setValue(data.type);
    this.updatePlanFormGroup.controls['method'].setValue(data.method);
    this.updatePlanFormGroup.controls['monthFrom'].setValue(data.monthFrom)
    this.updatePlanFormGroup.controls['yearFrom'].setValue(data.yearFrom)
  }

  groupHeaderClick(row) {
    if(!row?.articles?.length) {
      return
    }
    if (row.expanded) {
      row.expanded = false;
      const agg = [];
      this.groups.forEach(g =>
        g.expanded ? agg.push(g, ...g.articles) : agg.push(g)
      );
      this.dataSource.data = agg;
    } else {
      row.expanded = true;
      const agg = [];
      this.groups.forEach(g =>
        g.expanded ? agg.push(g, ...g.articles) : agg.push(g)
      );
      this.dataSource.data = agg;
    }
    timer(0).subscribe(() => {
      this._cdr.detectChanges()
    })
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  isObject(entry: string | number | object): boolean {
    return typeof entry === 'object' && !!entry
  }

  onCellValueChanged(event: unknown) {
    const total = (event['api'] as GridApi).getSelectedRows().map((el => el.plan_sum ? +el.plan_sum : 0)).reduce((a, b) => a + b, 0);
    this.createPlanFormGroup.controls['goal_rub'].setValue(total)
  }

  onSelectionChanged(event: unknown) {
    const total = (event['api'] as GridApi).getSelectedRows().map((el => el.plan_sum ? +el.plan_sum : 0)).reduce((a, b) => a + b, 0);
    this.createPlanFormGroup.controls['goal_rub'].setValue(total)
  }

  onCellValueChangedWhenUpdate(event: unknown) {
    const total = (event['api'] as GridApi).getSelectedRows().map((el => el.plan_sum ? +el.plan_sum : 0)).reduce((a, b) => a + b, 0);
    this.updatePlanFormGroup.controls['goal_rub'].setValue(total)
  }

  onSelectionChangedWhenUpdate(event: unknown) {
    const total = (event['api'] as GridApi).getSelectedRows().map((el => el.plan_sum ? +el.plan_sum : 0)).reduce((a, b) => a + b, 0);
    this.updatePlanFormGroup.controls['goal_rub'].setValue(total)
  }

  onFirstDataRendered(event: FirstDataRenderedEvent) {
    event.api.forEachNode((node) => {
        if(node.data.isChecked) {
          console.log(node.data.isChecked)
          node.setSelected(true)
        }
    });
  }
}
