import { ChangeDetectionStrategy,Component,  OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  MAIN_TAB_COLUMN_DEFAULT_DEF,
  OZON_MAIN_TAB_COLUMN_DEFS_BY_DATE,
  OZON_MAIN_TAB_COLUMN_DEFS_BY_PRODUCT,
  WB_MAIN_TAB_COLUMN_DEFS_BY_DATE,
  WB_MAIN_TAB_COLUMN_DEFS_BY_PRODUCT,
} from './main-test.mock';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import {
  ICardWidget,
  IMainTabTableData,
  TableGroupBy,
} from './main-test.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  ApexOptions,
  ApexPlotOptions,
} from 'ng-apexcharts/lib/model/apex-types';
import { THIRD_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { STRUCTURE_SALES_CHART } from '../../../shared/mocks/rates-mock';
import { ISelectedNgbDate } from '../../../shared/interfaces/common.interface';
import { forkJoin } from 'rxjs';
import { getChartOptions } from './main-test.func';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-main-test',
  templateUrl: './main-test.component.html',
  styleUrls: ['./main-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService],
})
export class MainBetaComponent extends AbstractMenuTabComponent implements OnInit {

  readonly defaultDef = MAIN_TAB_COLUMN_DEFAULT_DEF;
  readonly presets = THIRD_RANGE_TYPE;
  readonly tooltipIcon = faQuestionCircle;

  cards: ICardWidget[] | null = null;
  columnDefsByDate: (ColDef | ColGroupDef)[] | null = null;
  columnDefsProduct: (ColDef | ColGroupDef)[] | null = null;
  chartOptions: Partial<ApexOptions> | null = null;

  tableDataByDate = null;
  tableDataByProduct = null;
  footerDataForDate: Partial<IMainTabTableData>[] | null = null;
  footerDataForProduct: Partial<IMainTabTableData>[] | null = null;
  tableGroupingEnum = TableGroupBy;
  tableGroupingType = TableGroupBy.BY_DATE;
  dataForSalesStructureChart: Partial<ApexOptions> = STRUCTURE_SALES_CHART;
  rowSalesSubjectChartTitlesByBrand: string[] = [
    'Luis Vuitton',
    'Calvin Klein',
  ];
  seriesChartSaleStructureByBrand: number[] = [77, 33];
  rowSalesSubjectChartTitlesBySubjects: string[] = [
    'Шапки',
    'Варежки',
    'Брюки',
    'Куртки',
  ];
  seriesChartSaleStructureBySubjects: number[] = [33, 22, 33, 22];
  plotOptions: Partial<ApexPlotOptions> = { bar: { horizontal: true } };
  selectedDate: ISelectedNgbDate = {
    startDate: moment().subtract(30, 'day'),
    endDate: moment(),
  };
  lastReportDate = ''
   text = ''
  subtext = ''

  constructor() {
    super();
  }

  ngOnInit(): void {

    const profilePhone = localStorage.getItem('profilePhone');

    if(!!localStorage.getItem('hasBrokenToken') && JSON.parse(profilePhone) !== '+79032632693'){
      this._router.navigate(['settings', 'shop'])
    } else {
      this.getReportDate().pipe(untilDestroyed(this)).subscribe(data => {
        this.lastReportDate = data.last_data
      })
      this._mpSurfService
        .loadMetaData(this.SHOP_ID, this.TOKEN)
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          //@ts-ignore 
          const { die_text, die_title } = res.data.tutorialVideoLink.find(item => item.pageTitle === 'dashboard/rates') || { die_text: null, die_title: null }
          this.text = die_title
          this.subtext = die_text
        })
    }

  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  onDateChange(event: any): void {
    this.selectedDate = event;
    const params = {
      beginDate: this.selectedDate.startDate.format('YYYY-MM-DD'),
      endDate: this.selectedDate.endDate.format('YYYY-MM-DD'),
    }
    this.loadData(params)
  }

  groupTable(type: TableGroupBy): void {
    if (
      type === TableGroupBy.BY_DATE &&
      this.tableGroupingType === TableGroupBy.BY_DATE
    ) {
      return;
    }
    if (
      type === TableGroupBy.BY_PRODUCT &&
      this.tableGroupingType === TableGroupBy.BY_PRODUCT
    ) {
      return;
    }
    this.tableGroupingType = type;
  }

  loadData(params: Record<string, unknown>){
    forkJoin([
      this._mpSurfService.load(params, 'indicators/cards'),
      this._mpSurfService.load(params, 'indicators/chart-grid'),
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([cardsList, chartList]) => {
      this.cards = cardsList as ICardWidget[]
      this.chartOptions = getChartOptions(chartList)
      this.columnDefsByDate = +this.SHOP_ID !== 586 ? WB_MAIN_TAB_COLUMN_DEFS_BY_DATE : OZON_MAIN_TAB_COLUMN_DEFS_BY_DATE
      this.columnDefsProduct = +this.SHOP_ID !== 586 ? WB_MAIN_TAB_COLUMN_DEFS_BY_PRODUCT : OZON_MAIN_TAB_COLUMN_DEFS_BY_PRODUCT
      //@ts-ignore
      this.tableDataByDate = chartList.days
      //@ts-ignore
      this.tableDataByProduct = chartList.skus
      //@ts-ignore
      this.footerDataForDate = [{...chartList.total, date: 'Итого'}]
      //@ts-ignore
      this.footerDataForProduct = [{...chartList.total, photo_new: 'Итого'}]
      this._cdr.markForCheck()
    });
  }
}
